.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
table td{
  padding-bottom: 10px;
}
.title{
  color: #ffffff;
  font-size: 40px;
  font-weight: bolder;
}
.title-3{
  font-size: 12px;
}
.navbar-toggler{
  box-shadow: none !important;
  border: none !important;
}
.form-control{
  background-color: rgba(255, 255, 255, 0.386) !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 12px 0 0 12px !important;
}
.search-btn{
  background-color: #FFDC02 !important;
  border-radius: 0 12px 12px 0 !important;

}
.footer{
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
}
.border-r{
  border-radius: 12px;
  padding: 5px;
  background-color: #ffffff !important;
}

.bg-color{
  border-radius: 0 0 20px 20px;
  background-color: #01BDD6 !important;
}
.btn{
  border: none !important;
  box-shadow: none !important;
}
.btn-primary{
  border-radius: 12px !important;

  background-color: #e8f8fa !important;

}
.btn-outline-primary:focus{
  background-color: #d6f5f9 !important;
}
.btn-warning{
  border-radius: 12px !important;
  background-color: #FFDC02 !important;
}
.title-2{
font-size: 25px;
  color: #fbf0a9 !important;

}
.card{
  cursor: pointer;
  border-radius: 12px !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #f4fcfc66 !important;
  backdrop-filter: blur(12px);
}
.nav-item{
  background-color: #01bdd639;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 5px;
}
.offcanvas{
  background: rgba(255, 255, 255, 0.886) !important;
  backdrop-filter: blur(10px);
}
.btn-close{
  border: none !important;
}
.member-card{
  cursor: pointer;
  border-radius: 12px !important;
  border:none !important;
  box-shadow: none !important;
  background: linear-gradient(62deg, #b6e9ffec, #cff1fac9, #d7eefea8, #cee6f2dd);
     animation: gradient 30s ease infinite; 
      background-size: 400% 400%;
  backdrop-filter: blur(10px);
}
@-webkit-keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}
@keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}
.card-body{
  cursor: pointer;
  border-radius: 12px !important;
  transition: 0.3s;
}
.card-body:hover{
  background-color: #76f4fa81 !important;
  backdrop-filter: 20px;
}
.btn-outline-primary{
  box-shadow: none !important;
  border: 1px solid #e3f8fa !important;
}
.btn-active{
  background-color:#ddf8fc93 !important;
  backdrop-filter: blur(20px);
}
.btn-outline-primary:hover{
  background-color:#ddf8fc93 !important;
  backdrop-filter: blur(20px);
}

.btn-outline-primary:focus{
  background-color:#ddf8fc93 !important;
  backdrop-filter: blur(20px);
}
.gold-card{
  margin: auto;
  border-radius: 12px;
  height: 200px;
  padding: 10px;
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #f5bc41 30%, #f4c14b 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #fbe2b2 0%, #FFFFAC 8%, #D1B464 25%, #dcab37 62.5%, #e5b545 100%);
}
.diamond-text{
  color: rgb(79, 79, 79) !important;

}
.diamond-card{
  margin: auto;
  border-radius: 12px;
  height: 200px;
  padding: 10px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #dfe4e4 0%, #e3f8f6 8%, #dcebec 30%, #e0eceb 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #f6fefd 0%, #ebebe7 8%, #e6f1f4 25%, #f4feff 62.5%, #dce4e5 100%);
}

.diamond-card{
  background-color: rgb(255, 255, 255);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #01BDD6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.record-box{
  position: sticky;
  height: 75vh;
  overflow-y: scroll;
  padding-bottom: 30px;
}

.overlay{
  right: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  position:fixed;
  background-color: rgba(119, 227, 249, 0.589);
}
.invoice-card{
  min-height: 400px;
  border-radius: 12px;
  padding: 5px;
}

@media only screen and (max-width: 600px) {
  .record-box{
    position: sticky;
    height: 45vh;
    overflow-y: scroll;
    margin-bottom: 0px;
  }
}

/*waves****************************/
body {
  z-index: -5;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background: linear-gradient(315deg, rgb(8, 189, 216) 3%, rgb(119, 215, 247) 38%, rgb(83, 224, 246) 68%, rgb(64, 205, 226) 98%);
  /* animation: gradient 20s ease infinite; */
  background-size: 400% 400%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
      transform: translateX(1);
  }

  25% {
      transform: translateX(-25%);
  }

  50% {
      transform: translateX(-50%);
  }

  75% {
      transform: translateX(-25%);
  }

  100% {
      transform: translateX(1);
  }
}

/* text animateion */
